
/* global RESPONSIVE */
var frame,
    mejs;

frame = $('.js-slider');

// Se existe o frame
if (frame.length) {
    // Disparando a transformação dos sliders
    $('.js-slider').slider({
        itemsPerView: {
            LG: 3,
            XL: 4
        } 
    });
}

$(document).ready(function() {
    // se existe conteúdo de vídeo mejs para ajustar a sua altura
    mejs = $('.mejs__container');
    if (mejs.length) {
        mejs.each(function (idx) {
            let height = $(`#mep_${idx} > .mejs__inner > .mejs__mediaelement > mediaelementwrapper > video`).attr('height');
            if (height && height.length) {
                $(`#mep_${idx}`).css('height', height);
            }
        });
    }
});


// Chamada para o faux-link
$('.g-chamada, .noticia-extra, .historico-transmissoes .transmissao, .outras-transmissoes .l-transmissao').applyFauxLink();



